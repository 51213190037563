exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-personal-tsx": () => import("./../../../src/pages/personal.tsx" /* webpackChunkName: "component---src-pages-personal-tsx" */),
  "component---src-pages-presentacion-comunicacion-tsx": () => import("./../../../src/pages/presentacion_comunicacion.tsx" /* webpackChunkName: "component---src-pages-presentacion-comunicacion-tsx" */),
  "component---src-pages-projects-alianza-acercate-tsx": () => import("./../../../src/pages/projects/alianza-acercate.tsx" /* webpackChunkName: "component---src-pages-projects-alianza-acercate-tsx" */),
  "component---src-pages-projects-axa-tsx": () => import("./../../../src/pages/projects/axa.tsx" /* webpackChunkName: "component---src-pages-projects-axa-tsx" */),
  "component---src-pages-projects-cfosc-tsx": () => import("./../../../src/pages/projects/cfosc.tsx" /* webpackChunkName: "component---src-pages-projects-cfosc-tsx" */),
  "component---src-pages-projects-cisco-comics-tsx": () => import("./../../../src/pages/projects/cisco-comics.tsx" /* webpackChunkName: "component---src-pages-projects-cisco-comics-tsx" */),
  "component---src-pages-projects-cisco-live-tsx": () => import("./../../../src/pages/projects/cisco-live.tsx" /* webpackChunkName: "component---src-pages-projects-cisco-live-tsx" */),
  "component---src-pages-projects-coconat-tsx": () => import("./../../../src/pages/projects/coconat.tsx" /* webpackChunkName: "component---src-pages-projects-coconat-tsx" */),
  "component---src-pages-projects-feministisches-zentrum-migrantinnen-tsx": () => import("./../../../src/pages/projects/feministisches-zentrum-migrantinnen.tsx" /* webpackChunkName: "component---src-pages-projects-feministisches-zentrum-migrantinnen-tsx" */),
  "component---src-pages-projects-fortinet-tsx": () => import("./../../../src/pages/projects/fortinet.tsx" /* webpackChunkName: "component---src-pages-projects-fortinet-tsx" */),
  "component---src-pages-projects-friedrich-ebert-stiftung-tsx": () => import("./../../../src/pages/projects/friedrich-ebert-stiftung.tsx" /* webpackChunkName: "component---src-pages-projects-friedrich-ebert-stiftung-tsx" */),
  "component---src-pages-projects-himmel-8-tsx": () => import("./../../../src/pages/projects/himmel-8.tsx" /* webpackChunkName: "component---src-pages-projects-himmel-8-tsx" */),
  "component---src-pages-projects-ikaro-tsx": () => import("./../../../src/pages/projects/ikaro.tsx" /* webpackChunkName: "component---src-pages-projects-ikaro-tsx" */),
  "component---src-pages-projects-incuba-tsx": () => import("./../../../src/pages/projects/incuba.tsx" /* webpackChunkName: "component---src-pages-projects-incuba-tsx" */),
  "component---src-pages-projects-iniciativa-x-la-inclusion-tsx": () => import("./../../../src/pages/projects/iniciativa-x-la-inclusion.tsx" /* webpackChunkName: "component---src-pages-projects-iniciativa-x-la-inclusion-tsx" */),
  "component---src-pages-projects-instituto-cultural-mexico-alemania-tsx": () => import("./../../../src/pages/projects/instituto-cultural-mexico-alemania.tsx" /* webpackChunkName: "component---src-pages-projects-instituto-cultural-mexico-alemania-tsx" */),
  "component---src-pages-projects-international-rescue-committee-tsx": () => import("./../../../src/pages/projects/international-rescue-committee.tsx" /* webpackChunkName: "component---src-pages-projects-international-rescue-committee-tsx" */),
  "component---src-pages-projects-kreuzberger-himmel-tsx": () => import("./../../../src/pages/projects/kreuzberger-himmel.tsx" /* webpackChunkName: "component---src-pages-projects-kreuzberger-himmel-tsx" */),
  "component---src-pages-projects-natur-poesie-instituto-cultural-mexico-alemania-tsx": () => import("./../../../src/pages/projects/natur-poesie_instituto-cultural-mexico-alemania.tsx" /* webpackChunkName: "component---src-pages-projects-natur-poesie-instituto-cultural-mexico-alemania-tsx" */),
  "component---src-pages-projects-oracle-tsx": () => import("./../../../src/pages/projects/oracle.tsx" /* webpackChunkName: "component---src-pages-projects-oracle-tsx" */),
  "component---src-pages-projects-oxfam-tsx": () => import("./../../../src/pages/projects/oxfam.tsx" /* webpackChunkName: "component---src-pages-projects-oxfam-tsx" */),
  "component---src-pages-projects-salon-mexicano-de-ultramar-tsx": () => import("./../../../src/pages/projects/salon_mexicano_de_ultramar.tsx" /* webpackChunkName: "component---src-pages-projects-salon-mexicano-de-ultramar-tsx" */),
  "component---src-pages-projects-thi-tsx": () => import("./../../../src/pages/projects/thi.tsx" /* webpackChunkName: "component---src-pages-projects-thi-tsx" */),
  "component---src-pages-projects-wingu-tsx": () => import("./../../../src/pages/projects/wingu.tsx" /* webpackChunkName: "component---src-pages-projects-wingu-tsx" */),
  "component---src-pages-quienes-somos-tsx": () => import("./../../../src/pages/quienes_somos.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-tsx" */),
  "component---src-pages-valores-interdependencia-tsx": () => import("./../../../src/pages/valores/interdependencia.tsx" /* webpackChunkName: "component---src-pages-valores-interdependencia-tsx" */),
  "component---src-pages-valores-juego-tsx": () => import("./../../../src/pages/valores/juego.tsx" /* webpackChunkName: "component---src-pages-valores-juego-tsx" */),
  "component---src-pages-valores-liderazgo-tsx": () => import("./../../../src/pages/valores/liderazgo.tsx" /* webpackChunkName: "component---src-pages-valores-liderazgo-tsx" */),
  "component---src-pages-valores-pausa-tsx": () => import("./../../../src/pages/valores/pausa.tsx" /* webpackChunkName: "component---src-pages-valores-pausa-tsx" */),
  "component---src-pages-valores-presente-tsx": () => import("./../../../src/pages/valores/presente.tsx" /* webpackChunkName: "component---src-pages-valores-presente-tsx" */),
  "component---src-pages-valores-tsx": () => import("./../../../src/pages/valores.tsx" /* webpackChunkName: "component---src-pages-valores-tsx" */)
}

